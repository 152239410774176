var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('deliveryMonitoring.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('deliveryMonitoring.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.transaction_date))]),_c('td',[_vm._v(_vm._s(record.delivery_number))]),_c('td',[_vm._v(_vm._s(record.shop_code))]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.delivery_start_time))]),_c('td',[_vm._v(_vm._s(record.driver_name))]),_c('td',[_vm._v(_vm._s(record.sex))]),_c('td',[_vm._v(_vm._s(record.t_driver_name))]),_c('td',[_vm._v(_vm._s(record.t_sex))]),_c('td',[_vm._v(_vm._s(record.service_type_name_en))]),_c('td',[_vm._v(_vm._s(record.receiver_name))]),_c('td',[_vm._v(_vm._s(record.receiver_phone))]),_c('td',[_vm._v(_vm._s(record.delivery_status_remark))])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }