<template>
    <div>
        <ts-page-title
            :title="$t('deliveryMonitoring.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('deliveryMonitoring.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-overflow-x-scroll">
                    <ts-table
                        :columns="columns"
                        :records="resources"
                        :loading="loading"
                    >
                        <template v-slot="{ record }">
                            <td>{{ record.transaction_date }}</td>
														<td>{{ record.delivery_number }}</td>
														<td>{{ record.shop_code }}</td>
														<td>{{ record.shop_name }}</td>
														<td>{{ record.delivery_start_time }}</td>
														<td>{{ record.driver_name }}</td>
														<td>{{ record.sex }}</td>
														<td>{{ record.t_driver_name }}</td>
														<td>{{ record.t_sex }}</td>
														<td>{{ record.service_type_name_en }}</td>
														<td>{{ record.receiver_name }}</td>
														<td>{{ record.receiver_phone }}</td>
														<td>{{ record.delivery_status_remark }}</td>
                        </template>
                    </ts-table>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import Pusher from "pusher-js";
export default {
    name: "deliveryMonitory",
    data() {
        return {
            resources: [],
						loading: false
        };
    },
    mounted() {
        const pusher = new Pusher("48d25e04ac5d2be63b59", {
            cluster: "ap1"
        });

        const channel = pusher.subscribe("delivery.admin");
        channel.bind("delivery.monitoring", data => {
            this.resources.unshift(...data.data);
        });
    },
    computed: {
        columns() {
            return [
                { name: this.$t("deliveryMonitoring.transactionDate") },
                { name: this.$t("deliveryMonitoring.transactionNumber") },
                { name: this.$t("deliveryMonitoring.shopCode") },
                { name: this.$t("deliveryMonitoring.shopName") },
                { name: this.$t("deliveryMonitoring.deliveryStartTime") },
                { name: this.$t("deliveryMonitoring.driverName") },
                { name: this.$t("deliveryMonitoring.sex") },
                { name: this.$t("deliveryMonitoring.transferToDriver") },
                { name: this.$t("deliveryMonitoring.transferToSex") },
                { name: this.$t("deliveryMonitoring.serviceType") },
                { name: this.$t("deliveryMonitoring.receiverName") },
                { name: this.$t("deliveryMonitoring.receiverPhone") },
                { name: this.$t("deliveryMonitoring.status") }
            ];
        }
    }
};
</script>
